import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

type PdfLinks =
  | {
      contractWasUpdated: true;
      getPDFUrl: string;
      headPDFUrl: string;
    }
  | {
      contractWasUpdated: false;
      getPDFUrl: undefined;
      headPDFUrl: undefined;
    };

@Injectable({
  providedIn: 'root',
})
export class PdfLinkStateService {
  private pdfLinks = new ReplaySubject<PdfLinks>(1);
  pdfLinks$ = this.pdfLinks.asObservable();

  updatePdfLinks(newPdfLinks: PdfLinks) {
    this.pdfLinks.next(newPdfLinks);
  }
}
